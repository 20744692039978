// Wijmo overrides
.DataGrid {
  border: none;
  border-left: 1px solid bento-color(gray, 400);
  border-radius: 0;
}
.detailGrid {
  border-collapse: collapse !important;

  .wj-header {
    max-height: 41px !important;
  }
}
.DataGridFlexScroll {
  max-height: rem-calc(450);
}
.custom-popup .DataGridFlexScroll{
  max-height: rem-calc(400);
}
.DataGridFlexScroll .wj-cell {
  padding: 8px !important;
}
.ExtractFlexWidth .wj-cell {
  padding: 8px !important;
}

@media screen and (max-width: 4000px) and (min-width: 2500px) {
  .DataGridFlexScroll {
    max-height: rem-calc(690);
  }
  .custom-popup .DataGridFlexScroll{
    max-height: rem-calc(640);
  }
}
// Make scrollbar consistent across platforms on webkit browsers
.DataGrid [wj-part='root'] {
  scrollbar-color: rgba(bento-color(black), 0.4) bento-color(gray, 200);
  scrollbar-width: thin;
}
.DataGrid [wj-part='root']::-webkit-scrollbar {
  height: 11px;
  width: 11px;
}
.DataGrid [wj-part='root']::-webkit-scrollbar-thumb {
  background-color: rgba(bento-color(black), 0.3);
  border: 2px solid bento-color(gray, 200);
  border-radius: 6px;
}
.DataGrid [wj-part='root']::-webkit-scrollbar-track {
  background: bento-color(gray, 200);
}

// Layout
.DataGrid .wj-cell,
.DataGrid .wj-header {
  border-color: #858585; //bento-color(gray, 400);
}
.DataGrid .wj-cell {
  padding: rem-calc(16);
}
.DataGrid .wj-cell--input {
  padding-bottom: rem-calc(12);
  padding-top: rem-calc(12);
}

/* Overflow issue of .wj-cell when .wj-state-active is active */
/* The bue border is coming from marquee .wj-marquee which is assigned a z-index of 3 dynamically */
/* Reducing z-index of .wj-marquee prevents down/up arrow scrolling */
/* Targetting sticky/fixed headers globally */

.wj-flexgrid[ng-reflect-sticky-headers="true"] div[wj-part="ch"], .wj-flexgrid .wj-state-sticky{
  z-index: 4 !important;
}

.wj-flexgrid div[wj-part="ch"]{
  z-index: 4 !important;
}
  
.DataGrid .wj-colheaders .wj-header,
.DataGrid .wj-topleft .wj-cell.wj-header {
  background-color: bento-color(indigo, 100);
}
.DataGrid .wj-colheaders .wj-header {
  border-top: 1px solid #858585; //bento-color(gray, 400)
  font-size: rem-calc(14);
  font-weight: bento-weight(semiBold);
  padding-right: rem-calc(8);
}
.DataGrid .wj-frozen-col {
  box-shadow: rem-calc(20) 0 rem-calc(20) 0 rgba(bento-color(black), 0.05);
}
.DataGrid .wj-cell a {
  color: bento-color(indigo, 700) !important;
}
.DataGrid .wj-cell a:focus,
.DataGrid .wj-cell a:hover {
  cursor: pointer;
}
.DataGrid .wj-topleft .wj-header {
  border-top: 1px solid bento-color(gray, 400);
}
.DataGrid .wj-cell.wj-alt,
.DataGrid .wj-rowheaders .wj-cell.wj-header {
  background-color: bento-color(white, 600);
}
.DataGrid .wj-topleft .wj-cell.wj-header,
.DataGrid .wj-rowheaders .wj-cell.wj-header {
  border-color: #858585; //bento-color(gray, 400);
}
.DataGrid .wj-topleft .wj-header,
.DataGrid .wj-colheaders .wj-header {
  line-height: rem-calc(16);
  padding-bottom: rem-calc(12);
  padding-top: rem-calc(12);
}
.DataGrid .wj-cells .wj-cell.wj-state-selected,
.DataGrid .wj-cells .wj-cell.wj-state-multi-selected,
.DataGrid .wj-cells .wj-cell.row-selected,
.DataGrid .wj-cells .wj-cell.wj-state-active {
 // background-color: bento-color(indigo, 200);
 background-color: rgba(227, 241, 253, 1);
  color: inherit;
}


.DataGrid--views .wj-cells .wj-cell.wj-state-active {
  background-color:rgba(225, 244, 205,1);
  color: inherit;
}
.DataGrid--views .wj-topleft .wj-cell.wj-header{
  border : none !important;
  background-color: #FAFBFF !important;
}
.DataGrid--views .wj-rowheaders .wj-cell.wj-header {
  border-color: #858585;
  border: 1px solid #858585; 
}
.DataGrid--views [wj-part='tl'] {
  border: none; 
}

.DataGrid--views .wj-cell.wj-header.wj-state-dragSrc {
  opacity: 0.5!important;
  width: fit-content !important;
}
.DataGrid .wj-rowheaders .wj-header.wj-state-multi-selected {
  border-width: 1px;
}
.DataGrid--simple [wj-part='rh'] {
  overflow: visible !important;
}
.DataGrid--simple .wj-rowheaders .wj-header {
  box-shadow: rem-calc(20) 0 rem-calc(20) 0 rgba(bento-color(black), 0.05);
}

.DataGrid--simple .wj-cells .wj-cell.wj-state-active {
  background-color: bento-color(green, 200) !important;
  color: inherit;
}
.DataGrid--simple .wj-cell .wj-wrap .wj-state-active{
  background-color: bento-color(green, 200) !important;
  color: inherit;
}

.DataGrid--visualization [wj-part='rh'] {
  overflow: visible !important;
}
.DataGrid--visualization .wj-rowheaders .wj-header {
  box-shadow: rem-calc(20) 0 rem-calc(20) 0 rgba(bento-color(black), 0.05);
}
.DataGrid .wj-row:hover .wj-cell {
  background-color: bento-color(blueGray, 200);
}
.DataGrid .wj-row:hover .wj-header {
  background-color: bento-color(indigo, 100);
}
.DataGrid .wj-row:hover .wj-header:hover {
  background-color: bento-color(blueGray, 200);
}
.DataGrid--simple .wj-rowheaders .wj-row:first-child .wj-header {
  border-top: 1px solid bento-color(gray, 400);
  font-size: rem-calc(14);
  line-height: rem-calc(14);
}
.DataGrid--visualization .wj-rowheaders .wj-header {
  border-top: 1px solid bento-color(gray, 400);
  font-size: rem-calc(14);
  line-height: rem-calc(14);
}
.DataGrid--views {
  border-left: none;
}
.DataGrid--views .wj-cells .wj-cell:first-child,
.DataGrid--views .wj-colheaders .wj-row .wj-header:first-child {
  border-left: 1px solid bento-color(gray, 400);
}
.DataGrid--views .wj-colheaders .wj-row .wj-header:first-child {
  border-left: 1px solid bento-color(gray, 400);
}
.DataGrid--views .wj-colheaders .wj-row:first-child .wj-header {
  border-bottom: none;
}

//To be approved/verified by Brian
.DataGrid--views--pivot .wj-cells .wj-cell:first-child,
.DataGrid--views--pivot .wj-colheaders .wj-row .wj-header:first-child {
  border-left: 1px solid bento-color(gray, 400);
}
.DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header {
  border-bottom: none;
}
.DataGrid--views--pivot .wj-colheaders .wj-row .wj-header:first-child {
  border-left: 1px solid bento-color(gray, 400);
}

//To be approved/verified by Brian
.DataGrid--views--pivot {
  border-left: none;
}
.DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:first-child {
  background-color: bento-color(white);
  border-left-color: transparent;
  border-top-color: transparent;
}
// .DataGrid--views--pivot-unfroze .wj-colheaders .wj-row:first-child .wj-header:nth-child(2),
// .DataGrid--views--pivot-unfroze .wj-colheaders .wj-row:first-child .wj-header:nth-child(3) {
//   background-color: bento-color(white);
//   border-left-color: #afafaf !important;
//   border-top-color: #afafaf !important;
// }

//
.DataGrid--simple .wj-cells .wj-row:nth-child(1) .wj-cell {
  border-top: 1px solid bento-color(gray, 400);
  font-size: rem-calc(14);
  line-height: rem-calc(14);
}
.DataGrid--visualization .wj-row:nth-child(1) .wj-cells .wj-cell {
  border-top: 1px solid bento-color(gray, 400);
  font-size: rem-calc(14);
  line-height: rem-calc(14);
}

// Form
.DataGrid bento-checkbox {
  margin-right: 0;
}
.DataGrid [wj-part='cells'] .wj-cell .bento-select {
  height: rem-calc(32);
  position: relative;
}

// Controls
.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter {
  background-color: transparent;
  border-color: transparent;
}
.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter:focus,
.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter:hover {
  border-color: bento-color(indigo, 700);
  box-shadow: 1px 1px 4px 0 rgba(bento-color(black), 0.2);
}
.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter .wj-glyph-filter {
  color: bento-color(blue, 600);
}
.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter .wj-glyph-filter::after {
  content: '\e6a5';
}

// Indicators
.DataGrid-overrideIndicator {
  background-color: bento-color(indigo, 700);
  border-radius: 50%;
  display: inline-block;
  height: rem-calc(4);
  margin-bottom: rem-calc(2); // vertically align indicator
  margin-right: rem-calc(8);
  width: rem-calc(4);
}
.DataGrid .wj-cell--input .DataGrid-status {
  padding-bottom: rem-calc(4);
  padding-top: rem-calc(4);
}

// Bento overrides
.DataGrid .wj-cell--input .bento-select > .btn {
  z-index: auto;
}

@mixin hideCellProp {
  border-left-color: transparent !important;
  border-top-color: transparent !important;
}

.hideCell {
  background-color: bento-color(white) !important;

  @include hideCellProp;
}
.hideCellCreateView {
  @include hideCellProp;
}
.topLeft .wj-topleft {
  background-color: white;
}
//checkbox css of reorder
.checkbox-container {
  align-items: center;
  font-size: 14px;
  margin-top: rem-calc(20);
}
.checkbox-label {
  margin-right: rem-calc(10);
}
.cell-border {
  align-items: center !important;
  border: 1px solid #d0d0d0 !important;
  color: #193793 !important;
  cursor: all-scroll !important;
  // border-left: 1px solid #d0d0d0 !important;
  // border-top: 1px solid #d0d0d0 !important;
  // border-bottom: 1px solid #d0d0d0 !important;
  // border-right: none !important;
  display: flex !important;
  justify-content: center !important;
}
// .remove-border {
//   border-left: none !important;
// }
.wj-state-dragsrc {
  width: fit-content !important;
}

// Component specific
.FilterPanel--createView .DataGrid,
.ViewPanel-section--details .DataGrid,
.FilterPanel--createView .DataGrid--views .wj-colheaders .wj-row:first-child .wj-header:first-child,
.ViewPanel-section--details .DataGrid--views .wj-colheaders .wj-row:first-child .wj-header:first-child {
  background-color: bento-color(blueGray, 100);
}
.FilterPanel--visualization {
  padding: 1.25rem;
}

//To be approved/verified by Brian
.FilterPanel--createView .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:first-child,
.FilterPanel--createView .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:nth-child(2),
.FilterPanel--createView .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:nth-child(3),
.ViewPanel-section--details .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:first-child,
.ViewPanel-section--details .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:nth-child(2),
.ViewPanel-section--details .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:nth-child(3) {
  background-color: bento-color(blueGray, 100);
}
.cdk-visually-hidden {
  display: none !important;
}
.fms-input {
  width: 20rem;
}
.extractDiv {
  padding: 20px;
  padding-left: 0;
  padding-top: 20px;
}
.extractGrid {
  border-bottom: none;
  border-right: none;
  border-top: none;
  width: fit-content;
}
.glEditError {
  color: #8f221a;
}
.manageFirmInfoDiv {
  width: 45%;
}
.manageFirmPeerMonitorDiv {
  margin-left: 70px;
}
.divFlex {
  display: flex;
}
.manageFirmDiv {
  margin-top: -10px;
  width: 45%;
}
.manageFirmLabel {
  margin-top: 13px;
}
.varianceAuthorizeExtractButton {
  padding: 5px 50px;
}
.width50 {
  width: 50%;
}
.textDecorationNone {
  text-decoration: none;
}
.viewDataGrid {
  margin-top: 20px;
}
.breadCrumbColor {
  color: #083899;
  cursor: pointer;
}
.fontXXLarge {
  font-size: xx-large;
}
.custom-peer-groups-list .wj-header.wj-align-center{
  text-align: left !important;
}
.wj-flexgrid .wj-state-sticky .wj-header {
  opacity: unset !important;
 }

 .Chart-Datagrid .wj-cell[role="rowheader"]{
  pointer-events: none !important;
 }
 
 .viewDataGrid .DataGrid .wj-colheaders .wj-header,  .viewDataGrid .DataGrid .wj-row .wj-cell, .viewDataGrid .DataGrid .wj-row .wj-cell a {
    letter-spacing: 0 !important;
    word-spacing: 0 !important; 
    font-size: 15px;
    text-transform: capitalize;
  }
  
  .landing-page {
    .Section.Section--views.Section--overflow.Section--overlap{
      .Section-header{
        display: flex;
      }
      .reorder-checkbox-container{
        padding-left: 20px;
        .checkbox-container{
          margin-top: 0;
        }
      }
    }
    .h6.mb-0.DataGrid-heading{
      a{
        //color: #000 !important;
      }
     
    }
  }

  .trbranding-button{
    background-color: $color-trBrand !important;
    border-color: $color-trBrand !important;
    font: $font-trBrand !important;
    color: bento-color(white);
    width: 140px;
    margin-left: 10px;
    &:first-child{
      margin-left: 0;
    }
    text-align: center;
    &:hover, &:active, &:focus{
      background-color: $color-trBrandDark !important; 
      color: bento-color(white) !important;
    }
    &.active{
      color: bento-color(white);
      background-color: $color-trBrandDark;  
      
    }
}
.white-link{
  color: #fff !important;
}